@import 'scss/_core.scss';

* {
	margin: 0;
}

button {
	position: relative;
	top: 0;
	transition: top ease 0.5s;
	&:hover {
		cursor: pointer;
		top: -5px;
	}
}

a {
	text-decoration: none;
	color: var(--color-white);

	&:hover {
		cursor: pointer;
	}
}

body {
	background-image: url(../assets/backgroundImage.svg);
	background-size: cover;
	min-width: 400px;
}

.bold {
	font-weight: 800;
}

.gradientText {
	background: var(--gradient-primary);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}
