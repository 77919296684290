$black: 900;
$bold: 800;
$medium: 600;
$regular: 300;
$epilogue: 'Epilogue', sans-serif;
$inter: 'Inter';

@mixin header-1 {
	font-family: $epilogue;
	font-style: normal;
	font-weight: $black;
	font-size: 50px;
	line-height: 55px;
	text-align: center;
	letter-spacing: -0.06em;
}

@mixin header-2 {
	font-family: $epilogue;
	font-style: normal;
	font-weight: $black;
	font-size: 40px;
	line-height: 41px;
}

@mixin header-3 {
	font-family: $inter;
	font-style: normal;
	font-weight: $bold;
	font-size: 12px;
	line-height: 15px;
}

@mixin btn-1 {
	font-family: $epilogue;
	font-style: normal;
	font-weight: $bold;
	font-size: 22px;
	line-height: 23px;
	letter-spacing: -0.02em;
}

@mixin body {
	font-family: $inter;
	font-style: normal;
	font-weight: 200;
	font-size: 18px;
	line-height: 28px;
	letter-spacing: 0.02em;
}

@mixin body-link {
	font-family: $inter;
	font-style: normal;
	font-weight: $bold;
	font-size: 18px;
	line-height: 18px;
}

@mixin header-link {
	font-family: $epilogue;
	font-style: normal;
	font-weight: $black;
	font-size: 18px;
	line-height: 18px;
}
