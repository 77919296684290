@import 'scss/_typography.scss';

.tagContainer {
	width: fit-content;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 8px;
	padding: 7px 9px;
	border-radius: 8px;
	background-color: var(--color-black);
}

.tagText {
	@include body;
	font-size: 11px;
	color: var(--color-white);
	font-weight: 600;
	line-height: 13px;
}
