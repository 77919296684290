@import 'scss/_core.scss';

.button {
	border: none;
	border-radius: 15px;
	height: 61px;
	display: flex;
	justify-content: center;
	align-items: center;

	&.primary {
		padding: 18px 25px 18px 25px;
		background: var(--gradient-primary);
		color: var(--color-white);
		&:hover {
			box-shadow: var(--shadow-blue);
		}
	}

	&.secondary {
		padding: 18px 22px 18px 25px;
		background: var(--color-white);
		color: var(--color-black);
		&:hover {
			box-shadow: var(--shadow-white);
		}
	}
}

.buttonContent {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 21px;
}

.buttonText {
	@include btn-1;
	margin-top: 2px;
}
