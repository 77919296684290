@import 'scss/_core.scss';

.link {
	text-decoration: none;
	overflow: hidden;
	padding: 0.25em 0;
	width: fit-content;
	height: fit-content;
	display: block;
	position: relative;
	color: var(--color-white);

	&.bodyLink {
		@include body-link;
	}

	&.headerLink {
		@include header-link;
	}

	&:hover {
		cursor: pointer;
	}

	&::after {
		opacity: 1;
		transform: translate3d(-100%, 0, 0);
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 0.15em;
		background-color: var(--color-blue);
		opacity: 0;
		transition: opacity 300ms, transform 300ms;
	}
}

.link:hover::after,
.link:focus::after {
	opacity: 1;
	transform: translate3d(0, 0.2em, 0);
}

.link:hover::after,
.link:focus::after {
	transform: translate3d(0, 0, 0);
}
