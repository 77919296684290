@import 'scss/_typography.scss';

html {
    scroll-behavior: smooth;
}

@keyframes circle {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@keyframes inner-circle {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(-360deg);
    }
}

.topNavigationBar {
    height: 110px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 60px;
    margin-top: 20px;
}

.navigationBarProfilePicture {
    margin-left: 43px;
}

.navigationLinkContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 14px;
}

.navigationLink {
    margin-top: 2px;
}

.socialIconContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 29px;
    margin-left: auto;
    margin-right: 66px;
}

.socialIcon:hover {
    filter: opacity(75%);
    cursor: pointer;
}

.landingSection {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 120px;
}

.landingTextContainer {
    width: 970px;
}

.landingHeader {
    @include header-1;
    color: var(--color-white);
    width: 800px;
}

.gradientText {
    background: var(--gradient-primary);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.landingBodyText {
    @include body;
    color: var(--color-white);
    text-align: center;
    width: 850px;
    margin-top: 10px;
}

.buttonIcon {
    height: 30px;
    width: 30px;
}

.buttonsContainer {
    display: flex;
    flex-direction: row;
    gap: 25px;
    margin-top: 34px;
}

.aboutMeSection {
    margin-top: 252px;
    padding-top: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.aboutMeSectionContent {
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 700px;
    margin-left: 65px;
}

.aboutMePictureContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: 150px;
}

.aboutMeAnimationContainer {
    width: 340px;
    height: 340px;
    border: 2px dashed rgba(168, 164, 164, 0.4);
    border-radius: 200px;
    animation: circle 25s linear infinite;
    transform-origin: 50% 50%;
    position: relative;
}

.animationIcon {
    animation: inner-circle 25s linear infinite;
}

.animationCenterProfilePicture {
    margin-top: -255px;
}

.profileIcon {
    width: 50px;
    height: 50px;
}

.aboutMeTag {
    @include header-3;
    margin-top: 10px;
}

.aboutMeHeader {
    color: var(--color-white);
    @include header-2;
    margin-top: 27px;
}

.aboutMeDescriptionOne {
    @include body;
    color: var(--color-white);
    margin-top: 30px;
}

.aboutMeDescriptionTwo {
    @include body;
    color: var(--color-white);
    width: 650px;
    margin-top: 20px;
}

.experienceSection {
    margin-top: 314px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 50px;
}

.briefcaseIcon {
    width: 50px;
    height: 50px;
}

.experienceTag {
    @include header-3;
    margin-top: 10px;
}

.experienceHeader {
    color: var(--color-white);
    @include header-2;
    margin-top: 27px;
    text-align: center;
}

.experienceDescription {
    @include body;
    width: 876px;
    text-align: center;
    margin-top: 16px;
    color: var(--color-white);
}

.experienceSliderContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 34px;
}

.sliderBar {
    width: 995px;
    height: 55px;
    background-color: var(--color-white);
    border-radius: 15px;
}

.sliderIndicator {
    left: 0;
    position: relative;
    height: 4px;
    width: 0;
    background: var(--color-blue);
    top: 51px;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    transition: 0.3s;
}

.sliderBarButtonContainer {
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 73px;
    margin-top: -2px;
}

.sliderButtonFirst {
    margin-left: 43px;
}

.sliderButton {
    @include header-link;
    font-weight: 800;
    letter-spacing: 0.05em;
    color: var(--color-gray);
    margin-top: 4px;

    &:hover {
        color: var(--color-blue);
        cursor: pointer;
    }
}

.activeSliderButton {
    color: var(--color-blue);
}

.jobDetails {
    width: 900px;
}

.jobTitle {
    @include header-link;
    font-weight: 800;
    color: var(--color-white);
    margin-top: 39px;
}

.jobDates {
    @include body;
    color: var(--color-white);
    font-size: 14px;
    font-style: italic;
}

.jobDescription {
    margin-top: 15px;
    padding-left: 20px;
}

.jobDescriptionLine {
    @include body;
    color: var(--color-white);
    font-size: 16px;
}

.projectsSection {
    margin-top: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 50px;
}

.paletteIcon {
    width: 50px;
    height: 50px;
}

.projectsTag {
    @include header-3;
    margin-top: 10px;
}

.projectsHeader {
    color: var(--color-white);
    @include header-2;
    margin-top: 27px;
    text-align: center;
}

.projectsDescription {
    @include body;
    width: 876px;
    text-align: center;
    margin-top: 16px;
    color: var(--color-white);
}

.projectCardsContainer {
    margin-top: 70px;
    display: grid;
    grid-template-columns: 490px 490px;
    grid-template-rows: 520px 520px;
    row-gap: 25px;
    column-gap: 25px;
}

.contactSection {
    margin-top: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 50px;
}

.contactIcon {
    width: 50px;
    height: 50px;
}

.contactTag {
    @include header-3;
    margin-top: 10px;
}

.contactHeader {
    color: var(--color-white);
    @include header-2;
    margin-top: 27px;
    text-align: center;
}

.contactDescription {
    @include body;
    width: 876px;
    text-align: center;
    margin-top: 16px;

    color: var(--color-white);
}

.contactButton {
    margin-top: 30px;
}

.footerBar {
    width: 100%;
    background-color: var(--color-blue);
    height: 85px;
    margin-top: 230px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.footerText {
    @include btn-1;
    font-size: 20px;
    font-weight: 300;
    margin-left: 30px;
    letter-spacing: 0.02em;
    color: var(--color-white);
}

.footerIconContainer {
    margin-left: auto;
    margin-right: 30px;
    display: flex;
    flex-direction: row;
    gap: 30px;
    align-items: center;
}

.footerIcon {
    filter: opacity(0.5);

    &:hover {
        filter: opacity(1);
    }
}

// Responsive design media queries
@media only screen and (max-width: 1300px) {
    .aboutMeDescriptionOne,
    .aboutMeDescriptionTwo {
        width: 600px;
    }

    .aboutMePictureContainer {
        margin-right: 60px;
    }
}

@media only screen and (max-width: 1100px) {
    .aboutMeSection,
    .aboutMeSectionContent {
        flex-direction: column;
        align-items: center;
        margin-left: 0;
    }

    .aboutMeDescriptionOne,
    .aboutMeDescriptionTwo {
        text-align: center;
        width: 725px;
    }

    .aboutMePictureContainer {
        margin-right: auto;
        margin-left: auto;
        margin-top: 100px;
    }

    .landingBodyText {
        width: 800px;
    }

    .experienceSliderContainer {
        width: 90%;
    }

    .sliderBar {
        width: 100%;
    }
}

@media only screen and (max-width: 1035px) {
    .jobDetails,
    .experienceDescription,
    .experienceHeader {
        width: 90%;
    }

    .projectCardsContainer {
        grid-template-columns: 490px;
        grid-template-rows: 520px 520px 520px 520px;
        row-gap: 50px;
    }
}

@media only screen and (max-width: 960px) {
    .navigationBarProfilePicture {
        display: none;
    }

    .socialIconContainer {
        display: none;
    }

    .topNavigationBar {
        justify-content: center;
    }

    .projectsHeader,
    .projectsDescription {
        width: 90%;
    }

    .contactHeader,
    .contactDescription {
        width: 90%;
    }
}

@media only screen and (max-width: 850px) {
    .landingHeader,
    .landingBodyText {
        width: 90%;
    }
}

@media only screen and (max-width: 800px) {
    .aboutMeSectionContent {
        width: 80%;
    }

    .aboutMeDescriptionOne,
    .aboutMeDescriptionTwo {
        width: 100%;
    }

    .aboutMeHeader {
        text-align: center;
    }
}

@media only screen and (max-width: 675px) {
    .landingHeader {
        font-size: 40px;
        line-height: 42px;
    }

    .buttonsContainer {
        flex-direction: column;
    }

    .footerText {
        display: none;
    }

    .footerBar {
        justify-content: center;
    }

    .footerIconContainer {
        margin-right: auto;
    }
}

@media only screen and (max-width: 550px) {
    .navigationLinkContainer {
        img {
            display: none;
        }
    }

    .sliderBar {
        overflow: scroll;
    }

    .sliderIndicator {
        display: none;
    }

    .sliderBarButtonContainer {
        margin-top: 2px;
    }
}
