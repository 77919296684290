@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');

@import 'scss/_colors.scss';
@import 'scss/_typography.scss';

:root {
	--gradient-primary: #{$gradient-primary};

	--color-black: #{$color-black};
	--color-white: #{$color-white};
	--color-blue: #{$color-blue};
	--color-gray: #{$color-gray};

	--shadow-blue: #{$shadow-blue};
	--shadow-white: #{$shadow-white};
}
