@import 'scss/_typography.scss';

.projectContainer {
	background-color: white;
	width: 490px;
	height: 520px;
	border-radius: 25px;
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
	top: 0;
	transition: top ease 0.5s;

	&:hover {
		cursor: pointer;
		top: -5px;
		box-shadow: var(--shadow-white);
	}
}

.projectUpperWrapper {
	position: relative;
	width: 470px;
	height: 255px;
	margin-top: 10px;

	&:hover {
		.projectLinksContainer {
			display: flex;
		}

		.projectCoverImage {
			filter: brightness(0.4);
		}
	}
}

.projectLinksContainer {
	display: none;
	flex-direction: row;
	gap: 16px;
	width: fit-content;
	position: absolute;
	top: 20px;
	right: 20px;
	z-index: 100;
}

.projectCoverImage {
	width: 470px;
	height: 255px;
	position: absolute;
	border-radius: 20px;
	background-color: var(--color-white);

	&:hover {
		filter: brightness(0.4);
		cursor: default;
	}
}

.projectGithubIcon {
	width: 21px;
	height: 21px;
}

.projectGithubIcon,
.projectNewWindowIcon {
	&:hover {
		cursor: pointer;
	}
}

.projectName {
	@include btn-1;
	font-size: 24px;
	align-self: flex-start;
	margin-left: 28px;
	margin-top: 19px;
}

.projectToolsContainer {
	align-self: flex-start;
	display: flex;
	flex-direction: row;
	gap: 9px;
	align-items: center;
	margin-left: 28px;
	margin-top: 11px;
}

.projectDescription {
	@include body;
	font-size: 13px;
	margin-left: 28px;
	margin-top: 10px;
	margin-right: 30px;
	line-height: 24px;
}
